<template>
  <div class="text-right" :style="{ height: heights + 'px' }">
    <div class="body-bg">
      <div class="dflexs head">
        <img
          class="returns"
          @click="returns"
          src="../assets/iconrenter.png"
          alt=""
        />
        <div class="pagetitle">{{ $t("Level.title") }}</div>
      </div>
      <div>
        <img
          style="width: 100%; height: 100%; position: relative; margin-top: 5px"
          src="../assets/level/newimg/Mask group@3x_1.png"
          alt=""
        />
      </div>
      <div class="dingwei">
          <div class="huadongg">
            <div class="beijin">
              <span
                class="personLevel"
                @click="levelClick(0)"
                :title="$t('Level.personLevel')"
                >{{ $t("Level.personLevel") }}
                <img
                  v-show="urlSta == 0"
                  class=""
                  style="width: 0.46rem"
                  :src="imgfx"
                  alt=""
                />
              </span>
              <span
                class="wealthLevel"
                @click="levelClick(1)"
                :title="$t('Level.wealthLevel')"
                >{{ $t("Level.wealthLevel") }}
                <img
                  v-show="urlSta == 1"
                  class=""
                  style="width: 0.46rem"
                  :src="imgfx"
                  alt=""
                />
              </span>
              <span
                class="charmLevel"
                @click="levelClick(2)"
                :title="$t('Level.charmLevel')"
                >{{ $t("Level.charmLevel") }}
                <img
                  v-show="urlSta == 2"
                  class=""
                  style="width: 0.46rem"
                  :src="imgfx"
                  alt=""
                />
              </span>
            </div>
   
          </div>


        </div>
      <van-swipe
              @change="onClick"
              height="100%"
              :show-indicators="false"
              ref="swiper"
            >
              <van-swipe-item>
                <div>
                  <div style="padding-top: 0.6rem">
                    <div class="urlRank-icon">
                      <div
                        class="urlRank"
                        :style="{
                          backgroundImage:
                            'url(' + personLevelData.personalLevelUrl + ')',
                        }"
                      >
                      </div>
                    </div>
                  </div>
                  <div class="currentExpbox">
                    <div
                      style="
                        color: #deb78870;
                        padding-bottom: 0.06rem;
                        font-size: 0.3rem;
                        line-height: 1.5;
                        text-align: center;
                      "
                    >
                      <div style="color: #e2aa78">
                        {{ personLevelData.personalNowLevelExp }}
                      </div>
                      <div style="font-size: 0.25rem">
                        {{ $t("Level.currentExp") }}
                      </div>
                    </div>
                    <div class="progress_barbox">
                      <div
                        class="currentExp"
                        :style="{width: (personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'1':personLevelData.personalNowLevelExp/personLevelData.nowLevelExp)*100 +'%'}"
                      ></div>
                    </div>
                    <div
                      style="
                        color: #deb888;
                        padding-bottom: 0.1rem;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0.17rem;
                        font-size: 0.2rem;
                      "
                    >
                      <div>
                        <span style="flex: 1"
                          >LV.{{ personLevelData.personalLevelLeft }}</span
                        >

                        <span  v-if="this.$i18n.locale == 'zh' || this.$i18n.locale == 'tc'">(<span>升级需要{{ personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'0':personLevelData.nowLevelExp-personLevelData.personalNowLevelExp }}</span>)</span>
                        <span v-if="this.$i18n.locale == 'en'">(<span>Need {{ personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'0':personLevelData.nowLevelExp-personLevelData.personalNowLevelExp }} to upgrade</span>)</span>
                        <span v-if="this.$i18n.locale == 'ar'">(<span>تحتاج {{ personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'0':personLevelData.nowLevelExp-personLevelData.personalNowLevelExp }} للترقية</span>)</span>
                        <span v-if="this.$i18n.locale == 'tr'">(<span>Yükseltme {{  personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'0':personLevelData.nowLevelExp-personLevelData.personalNowLevelExp }}Gerektirir</span>)</span>
                        <span v-if="this.$i18n.locale == 'pt'">(<span>Para subir a nível requer{{ personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'0':personLevelData.nowLevelExp-personLevelData.personalNowLevelExp }}</span>)</span>
                        <span v-if="this.$i18n.locale == 'es'">(<span>La actualización requiere{{ personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'0':personLevelData.nowLevelExp-personLevelData.personalNowLevelExp }}</span>)</span>
                      </div>
                      <div>
                        <span>LV.{{ personLevelData.personalLevelRight }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </van-swipe-item>
              <van-swipe-item>
                <div>
                  <div style="padding-top: 0.6rem">
                    <div class="urlRank-icon">
                      <div
                        class="urlRank"
                        :style="{
                          backgroundImage:
                            'url(' + wealthLevelData.wealthLevelUrl + ')',
                        }"
                      >
                      </div>
               
                    </div>
  
                  </div>
                  <div class="currentExpbox">
                    <div
                      style="
                        color: #deb78870;
                        padding-bottom: 0.06rem;
                        font-size: 0.3rem;
                        line-height: 1.5;
                        text-align: center;
                      "
                    >
                    <div style="color: #e2aa78">{{ wealthLevelData.personalNowWealthLevelExp }}</div>
                    <div style="font-size: 0.25rem">{{ $t("Level.currentExp") }}</div>
                    </div>
                    <div class="progress_barbox">
                      <div
                        class="currentExp"
                        :style="{width: (wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'1':wealthLevelData.personalNowWealthLevelExp/wealthLevelData.nowWealthLevelExp)*100 +'%'}"
                      ></div>
                    </div>
                    <div
                      style="
                        color: #deb888;
                        padding-bottom: 0.1rem;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0.17rem;
                        font-size: 0.2rem;
                      "
                    >
                    <div>
                      <span style="flex: 1">LV.{{ wealthLevelData.wealthLevelLeft }}</span> 
        
                        <span  v-if="this.$i18n.locale == 'zh' || this.$i18n.locale == 'tc'">(<span>升级需要{{wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'0':wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp}}</span>)</span>
                        <span v-if="this.$i18n.locale == 'en'">(<span>Need {{wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'0':wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp}} to upgrade</span>)</span>
                        <span v-if="this.$i18n.locale == 'ar'">(<span>تحتاج {{wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'0':wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp}} للترقية</span>)</span>
                        <span v-if="this.$i18n.locale == 'tr'">(<span>Yükseltme {{wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'0':wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp}}Gerektirir</span>)</span>
                        <span v-if="this.$i18n.locale == 'pt'">(<span>Para subir a nível requer{{wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'0':wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp}}</span>)</span>
                        <span v-if="this.$i18n.locale == 'es'">(<span>La actualización requiere{{wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'0':wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp}}</span>)</span>



                    </div>

                      <span>LV.{{ wealthLevelData.wealthLevelRight }}</span>
                     
                    </div>
                  </div>
                </div>
              </van-swipe-item>

              <van-swipe-item>
                <div>
                  <div style="padding-top: 0.6rem">
                    <div class="urlRank-icon">
                      <div
                        class="urlRank"
                        :style="{
                          backgroundImage:
                            'url(' + charmLevelInfo.glamourLevelUrl + ')',
                        }"
                      >
                      </div>
              
                    </div>

                  </div>
                  <div class="currentExpbox">
                    <div
                      style="
                        color: #deb78870;
                        padding-bottom: 0.06rem;
                        font-size: 0.3rem;
                        line-height: 1.5;
                        text-align: center;
                      "
                    >
                    <div  style="color: #e2aa78">
                      {{ charmLevelInfo.personalNowGlamourLevelExp }}
                    </div>
                    <div style="font-size: 0.25rem">
                        {{ $t("Level.currentExp") }}
                    </div>
                    </div>
                    <div class="progress_barbox">
                      <div
                        class="currentExp"
                        :style="{width: (charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'1':charmLevelInfo.personalNowGlamourLevelExp/charmLevelInfo.nowGlamourLevelExp)*100 +'%'}"
                      ></div>
                    </div>
                    <div
                      style="
                        color: #deb888;
                        padding-bottom: 0.1rem;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0.17rem;
                        font-size: 0.2rem;
                      "
                    >
                      
                      <div>
                                      <span style="flex: 1"
                        >LV.{{ charmLevelInfo.glamourLevelLeft }}</span
                      >
                        <span  v-if="this.$i18n.locale == 'zh' || this.$i18n.locale == 'tc'">(<span>升级需要{{charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'0':charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp}}</span>)</span>
                        <span v-if="this.$i18n.locale == 'en'">(<span>Need {{charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'0':charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp}} to upgrade</span>)</span>
                        <span v-if="this.$i18n.locale == 'ar'">(<span>تحتاج {{charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'0':charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp}} للترقية</span>)</span>
                        <span v-if="this.$i18n.locale == 'tr'">(<span>Yükseltme {{charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'0':charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp}}Gerektirir</span>)</span>
                        <span v-if="this.$i18n.locale == 'pt'">(<span>Para subir a nível requer{{charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'0':charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp}}</span>)</span>
                        <span v-if="this.$i18n.locale == 'es'">(<span>La actualización requiere{{charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'0':charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp}}</span>)</span>


                      </div>
                      <div>
                                   <span>LV.{{ charmLevelInfo.glamourLevelRight }}</span>
                      </div>
           
                    </div>
                  </div>
                </div>
              </van-swipe-item>
      </van-swipe>
      <div style="margin-bottom: 0.4rem;position: absolute;top: 475px;bottom: 0;left: 0;right: 0;margin: auto;">
            <div class="help">
              <div
                v-if="activeIndicator == 0"
                class="flexs upgradebox"
                v-for="(item, index) in $t('Level.upgradebox.person')"
                :key="index"
              >
                <img
                  v-if="index == 0"
                  style="width: 0.5rem"
                  src="../assets/level/newimg/Group 1519@3x.png"
                />
                <img
                  v-if="index == 1"
                  style="width: 0.5rem"
                  src="../assets/level/newimg/Group 1517@3x.png"
                />
                <div class="contentbox">
                  <span class="titleName">{{ item.title }}</span>
                  <span style="color: #deb888; font-size: 0.23rem">{{
                    item.value
                  }}</span>
                </div>
              </div>
              <div
                v-if="activeIndicator == 1"
                class="flexs upgradebox"
                v-for="(item, index) in $t('Level.upgradebox.wealth')"
                :key="index"
              >
                <img
                  v-if="index == 0"
                  style="width: 0.5rem"
                  src="../assets/level/newimg/Group 1519@3x.png"
                />
                <img
                  v-if="index == 1"
                  style="width: 0.5rem"
                  src="../assets/level/newimg/Group 1517@3x.png"
                />
                <div class="contentbox">
                  <div class="titleName">{{ item.title }}</div>
                  <div style="color: #deb888; font-size: 0.23rem">
                    {{ item.value }}
                  </div>
                </div>
              </div>

              <div
                v-if="activeIndicator == 2"
                class="flexs upgradebox"
                v-for="(item, index) in $t('Level.upgradebox.charm')"
                :key="index"
              >
                <img
                  v-if="index == 0"
                  style="width: 0.5rem"
                  src="../assets/level/newimg/Group 1519@3x.png"
                />
                <img
                  v-if="index == 1"
                  style="width: 0.5rem"
                  src="../assets/level/newimg/Group 1517@3x.png"
                />
                <div class="contentbox">
                  <div class="titleName">{{ item.title }}</div>
                  <div style="color: #deb888; font-size: 0.23rem">
                    {{ item.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
    <div class="level-box" v-if="badgeReward">
      <img class="level-img"  src="../assets/level/newimg/Group 1569@3x.png" alt="">
      <p class="level-txt">{{$t('Level.badgeReward')}}</p>

      <div style="margin-bottom: 0.2rem;" v-if="activeIndicator==0">
                <van-grid :border="false" :column-num="3">
                  <van-grid-item v-for="(item, index) in badgeReward.person" :key="index">
                 
                    <img style="height: 0.4rem;" :src="item.lvimg" alt="">
                    <span class="rankvalue">{{item.value}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
              <div style="margin-bottom: 0.2rem;" v-if="activeIndicator==1">
                <van-grid :border="false" :column-num="3">
                  <van-grid-item v-for="(item, index) in badgeReward.wealth" :key="index">
                
                    <img style="height: 0.6rem;" :src="item.lvimg" alt="">
                    <span class="rankvalue">{{item.value}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
              <div style="margin-bottom: 0.2rem;" v-if="activeIndicator==2">
                <van-grid :border="false" :column-num="3">
                  <van-grid-item v-for="(item, index) in badgeReward.charmLevel" :key="index">
               
                    <img style="height: 0.6rem;" :src="item.lvimg" alt="">
                    <span class="rankvalue">{{item.value}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
    </div>

    <div class="level-box" v-if="heads">
      <img class="level-img"  src="../assets/level/newimg/Group 1570@3x.png" alt="">
      <p class="level-txt">{{$t('Level.avatarFrame')}}</p>
      <div style="margin-bottom: 0.2rem;">
                <van-grid :border="false" :column-num="3">
                  <van-grid-item v-for="(item, index) in heads" :key="index">
                 
                    <img style="height: 76px;width: 80px;" :src="item.url" alt="">
                    <span class="rankvalue">{{item.level}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
    </div>

    <div class="level-box" v-if="mounts">
      <img class="level-img"  src="../assets/level/newimg/Group 1571@3x.png" alt="">
      <p class="level-txt">{{$t('Level.entranceEffect')}}</p>
      <div style="margin-bottom: 0.2rem;">
                <van-grid :border="false" :column-num="3">
                  <van-grid-item v-for="(item, index) in mounts" :key="index">
                 
                    <img style="height: 76px;width: 80px;" :src="item.url" alt="">
                    <span class="rankvalue">{{item.level}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
    </div>
    </div>
  </div>
</template>

<script type="text/babel">
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
import Cookies from "js-cookie";
import leftimg from "../assets/level/left.png";
import rightimg from "../assets/level/right.png";
import leftbr from "../assets/level/leftbr.png";
import rightbr from "../assets/level/rightbr.png";
import bottomurl from "../assets/level/imgin/tab.png";

//个人等级
import LV1_10 from "../assets/level/newimg/Group 1692@3x.png";
import LV21_30 from "../assets/level/newimg/Group 1693@3x.png";
import LV11_20 from "../assets/level/newimg/Group 1694@3x.png";
import LV31_40 from "../assets/level/newimg/Group 1695@3x.png";
import LV41_50 from "../assets/level/newimg/Group 1696@3x.png";
import LV51_60 from "../assets/level/newimg/Group 1691@3x.png";
import LV61_70 from "../assets/level/newimg/Group 1690@3x.png";
import LV71_80 from "../assets/level/newimg/Group 1689@3x.png";
import LV81_90 from "../assets/level/newimg/Group 1688@3x.png";
import LV91_100 from "../assets/level/newimg/Group 1687@3x.png";

//财富等级
import cfLV1_15 from "../assets/level/newimg/Group 1697@3x.png";
import cfLV16_30 from "../assets/level/newimg/Group 1698@3x.png";
import cfLV31_45 from "../assets/level/newimg/Group 1699@3x.png";
import cfLV46_60 from "../assets/level/newimg/Group 1700@3x.png";
import cfLV61_75 from "../assets/level/newimg/Group 1701@3x.png";
import cfLV76_99 from "../assets/level/newimg/Group 1702@3x.png";

//魅力等级
import mlLV1_15 from "../assets/level/newimg/Group 1708@3x.png";
import mlLV16_30 from "../assets/level/newimg/Group 1707@3x.png";
import mlLV31_45 from "../assets/level/newimg/Group 1706@3x.png";
import mlLV46_60 from "../assets/level/newimg/Group 1705@3x.png";
import mlLV61_75 from "../assets/level/newimg/Group 1704@3x.png";
import mlLV76_100 from "../assets/level/newimg/Group 1703@3x.png";

export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      donghua: "",
      danmus: [],
      // personLevelData: {
      //   currentExp: 1000,
      //   rquiredExp: 3000,
      //   currentRank: 12,
      //   nextRank: 13,
      // },
      personLevelData: [],
      wealthLevelData: [],
      heads: [],
      mounts: [],
      personHeads: [],
      personMounts: [],
      wealthHeads: [],
      wealthMounts: [],
      charmLevelInfo: [],

      urlRank: "",

      tasklist: null,
      index: 0,
      disabled: false,
      status: true,
      headers: {
        deviceId: "",
        lang: "",
        version: "",
        os: "",
        model: "",
        fid: "",
      },
      recordlist: [],
      userInfo: [],
      emptyRecord: false,
      token: null,
      uid: null,
      headers: null,
      //  token:
      //    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOREV5IiwieHl6IjoiWmpsalpEUTVZakpqT0daaU5HTmlNV0ZpTnpWbFkyWmlNell3WmpnNVlqVT0iLCJleHAiOjE5MDc3NDM3NDB9.irA-gNwNYSD9s6_BQH2nwiQU8Zprq5BS2nyHhqwIRYY",
      // uid: 412,
      // headers: [
      //   "Content-Type:" + "application/json; charset=utf-8",
      //   "deviceId:" + "f9cd49b2c8fb4cb1ab75ecfb360f89b5",
      //   "lang:" + "zh_CN",
      //   "version:" + "2.2.0",
      //   "os:" + "1",
      //   "model:" + "M2011K2C",
      //   "fid:" + "0",
      // ],
      myiCode: "",
      active: "",
      width: 55,
      imgfx: bottomurl,
      leftbr: leftbr,
      rightbr: rightbr,
      heights: 0,
      slideh: 0,
      badgeReward: {
        person: [
          { lvimg: LV1_10, value: "LV.1-10" },
          { lvimg: LV11_20, value: "LV.11-20" },
          { lvimg: LV21_30, value: "LV.21-30" },
          { lvimg: LV31_40, value: "LV.31-40" },
          { lvimg: LV41_50, value: "LV.41-50" },
          { lvimg: LV51_60, value: "LV.51-60" },
          { lvimg: LV61_70, value: "LV.61-70" },
          { lvimg: LV71_80, value: "LV.71-80" },
          { lvimg: LV81_90, value: "LV.81-90" },
          { lvimg: LV91_100, value: "LV.91-100" },
        ],
        wealth: [
          { lvimg: cfLV1_15, value: "LV.1-15" },
          { lvimg: cfLV16_30, value: "LV.16-30" },
          { lvimg: cfLV31_45, value: "LV.31-45" },
          { lvimg: cfLV46_60, value: "LV.46-60" },
          { lvimg: cfLV61_75, value: "LV.61-75" },
          { lvimg: cfLV76_99, value: "LV.76-99" },
        ],
        charmLevel: [
          { lvimg: mlLV1_15, value: "LV.1-15" },
          { lvimg: mlLV16_30, value: "LV.16-30" },
          { lvimg: mlLV31_45, value: "LV.31-45" },
          { lvimg: mlLV46_60, value: "LV.46-60" },
          { lvimg: mlLV61_75, value: "LV.61-75" },
          { lvimg: mlLV76_100, value: "LV.76-100" },
        ],
      },
      activeIndicator: 0,
      str1: "",
      urlSta: "",
    };
  },

  created() {
    // this.$completes.loadErudaJs()
    // this.personLevel()
    // this.invitationRecord()

    $(function () {
      $(".beijin>span").click(function () {
        $(this).css("color", "#DEB888");
        $(this).siblings().css("color", "rgb(216 186 150 / 30%)");
      });
    });
  },
  mounted() {
    //页面css初始化
    // document.getElementsByTagName("html")[0].style.backgroundColor = "#8260FF";
    document.documentElement.style.overflow = "hidden";
    document.getElementsByTagName("html")[0].style.padding = "0";
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);
    this.heights = window.screen.height;
    var huadongg = document.getElementsByClassName("huadongg");
    this.slideh = window.screen.height - huadongg[0].offsetHeight;
    // let payment_url = 'https://help.koudailive.com/pact/#/familyLevel?type=1%3FlangType%3Dzh_CN'
    let payment_url = location.hash;
    console.log(payment_url, "payment_url");
    let askIndex = payment_url.indexOf("?");
    var newStr = payment_url.slice(askIndex);
    let strs = decodeURIComponent(newStr.split("=")[1]);
    let ipos = strs.indexOf("?"); //指定开始的字符串
    let str1s = strs.slice(0, ipos);
    this.str1 = str1s;
    if (str1s == 0) {
      this.levelClick(0);
    }
    if (str1s == 1) {
      this.levelClick(1);
    }
    if (str1s == 2) {
      this.levelClick(2);
    }
  },

  methods: {
    //返回
    returns() {
      this.$completes.complete("getPreviousPage");
    },

    //原生返回参数
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      const userinfos = Cookies.get('userinfos')
      if (userinfos != undefined) {
        if (userinfos.uid == this.uid) {
          this.myiCode = userinfos.myiCode
        }
      }
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      // let langs = "zh";
      // let lang = "";
      if (langs == "zh") {
        if (lang == "zh_TW" || lang == "zh_HK") {
          this.$i18n.locale = "tc";
        } else {
          this.$i18n.locale = "zh";
        }
      } else if (langs == "en") {
        this.$i18n.locale = "en";
      } else if (langs == "ar") {
        this.$i18n.locale = "ar";
      } else if (langs == "tr") {
        this.$i18n.locale = "tr";
      } else if (langs == "pt") {
        this.$i18n.locale = "pt";
      } else if (langs == "es") {
        this.$i18n.locale = "es";
      } else {
        this.$i18n.locale = "en";
      }
      // this.$i18n.locale = 'ar'
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      document.title = this.$t("inviteCash.title");
      this.personLevel();
    },

    leftslip() {
      this.imgfx = bottomurl;
      $(".beijin>span").eq(1).css("color", "#DEB888");
      $(".beijin>span").eq(0).css("color", "rgb(216 186 150 / 30%)");
      $(".beijin>span").eq(2).css("color", "rgb(216 186 150 / 30%)");
    },
    rightslip() {
      this.imgfx = bottomurl;
      $(".beijin>span").eq(0).css("color", "#DEB888");
      $(".beijin>span").eq(1).css("color", "rgb(216 186 150 / 30%)");
      $(".beijin>span").eq(2).css("color", "rgb(216 186 150 / 30%)");
    },

    charmColor() {
      this.imgfx = bottomurl;
      $(".beijin>span").eq(2).css("color", "#DEB888");
      $(".beijin>span").eq(0).css("color", "rgb(216 186 150 / 30%)");
      $(".beijin>span").eq(1).css("color", "rgb(216 186 150 / 30%)");
    },

    levelClick(idx) {
      this.urlSta = idx;
      if (idx == 1) {
        this.leftslip();
        if (idx != this.$refs.swiper.activeIndicator) {
          this.$refs.swiper.swipeTo(idx);
        }
      } else if (idx == 0) {
        this.rightslip();
        if (idx != this.$refs.swiper.activeIndicator) {
          this.$refs.swiper.swipeTo(idx);
        }
      } else {
        this.charmColor();
        this.$refs.swiper.swipeTo(idx);
      }
    },

    onClick(idx) {
      this.urlSta = idx;
      this.activeIndicator = this.$refs.swiper.activeIndicator;
      if (idx == 1) {
        this.leftslip();
        this.heads = this.wealthHeads;
        console.log(' this.heads1111 ', this.heads);
        this.mounts = this.wealthMounts;
      } else if (idx == 0) {
        this.rightslip();
        this.heads = this.personHeads;
        console.log(' this.heads0000 ', this.heads);

        this.mounts = this.personMounts;
      } else {
        this.charmColor();
        this.heads = "";
        this.mounts = "";
      }
    },

    personLevel() {
      let that = this;
      //得到用户个人等级
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/user/getUserLevelInfo?token=" +
          that.token +
          "&uid=" +
          that.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
          console.log(data, "data");
          that.personLevelData = data.data;
        }
      });
      //得到用户个人等级头像框
      let personLevelheads = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/user/getUserLevelFramelessHeads?token=" +
          that.token +
          "&uid=" +
          that.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLevelheads).then((data) => {
        if (data.code == 200) {
          that.personHeads = data.data;
          if (that.str1 == 0) {
            that.heads = data.data;
          }
        }
      });
      //得到用户个人等级坐骑
      let personLevelmounts = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/user/getUserLevelMount?token=" +
          that.token +
          "&uid=" +
          that.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLevelmounts).then((data) => {
        if (data.code == 200) {
          that.personMounts = data.data;
          if (that.str1 == 0) {
            that.mounts = data.data;
          }
        }
      });

      //得到用户财富等级
      let wealthLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/user/getUserWealthLevelInfo?token=" +
          that.token +
          "&uid=" +
          that.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(wealthLeveldatas).then((data) => {
        if (data.code == 200) {
          console.log(data, "data2222");
          that.wealthLevelData = data.data;
        }
      });
      //得到用户财富等级头像框
      let wealthLevelheads = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/user/getUserWealthLevelFramelessHeads?token=" +
          that.token +
          "&uid=" +
          that.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(wealthLevelheads).then((data) => {
        if (data.code == 200) {
          that.wealthHeads = data.data;
          if (that.str1 == 1) {
            that.heads = data.data;
        console.log(' this.heads1111 ', this.heads);

          }
        }
      });
      //得到用户财富等级坐骑
      let wealthLevelmounts = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/user/getUserWealthLevelMount?token=" +
          that.token +
          "&uid=" +
          that.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(wealthLevelmounts).then((data) => {
        if (data.code == 200) {
          that.wealthMounts = data.data;
          if (that.str1 == 1) {
            that.mounts = data.data;
          }
        }
      });

      let charmLevelData = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/user/getUserGlamourLevelInfo?token=" +
          that.token +
          "&uid=" +
          that.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(charmLevelData).then((data) => {
        if (data.code == 200) {
          that.charmLevelInfo = data.data;
        }
      });
    },
  },
};
</script>

<style>
.text-right{
  overflow: scroll;
}
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */

html[lang="ar"] .text-right {
  /* direction: rtl; */
}

html[lang="ar"] .head {
  direction: rtl;
}

html[lang="ar"] .pagetitle {
  margin-left: 0.4rem;
  margin-right: 0;
}

html[lang="ar"] .returns {
  -webkit-transform: rotateY(180deg);
}

html[lang="ar"] .beijin {
  direction: rtl;
}

html[lang="ar"] .beijin > img {
  -webkit-transform: rotateY(180deg);
}

html[lang="ar"] .personLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;
  /* text-align: left; */
}
html[lang="en"] .personLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
}
html[lang="pt"] .personLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
}
html[lang="es"] .personLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
}
html[lang="tr"] .personLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
}

html[lang="ar"] .wealthLevel {
  /* padding-right: 0.5rem; */
  padding-left: 0;
  color: rgb(216 186 150 / 30%);
  width: 30%;
  text-align: center;
  /* text-align: right; */
}
html[lang="ar"] .charmLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
  /* text-align: center; */
  /* text-align: right; */
}

html[lang="en"] .charmLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;
}
html[lang="pt"] .charmLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;
}
html[lang="es"] .charmLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;
}
html[lang="tr"] .charmLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;
}

html[lang="ar"] .currentExpbox {
  direction: rtl;
}

html[lang="ar"] .van-grid {
  direction: rtl;
}

html[lang="ar"] .avatarFrame {
  direction: rtl;
}

html[lang="ar"] .ffectsimg {
  margin-right: 0;
  margin-left: 0.2rem;
}

html[lang="ar"] .avatarFramebox {
  margin-right: 0;
  margin-left: 0.2rem;
}

html[lang="ar"] .help {
  direction: rtl;
}

html[lang="ar"] .contentbox {
  text-align: right;
  margin-right: 0.3rem;
}

.van-swipe {
  padding-bottom: 0.8rem;
  position: absolute;
  top: 138px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

}
.body-bg{
  /* height: 620px; */
  /* height: 100%; */
  /* background-image: url("../assets/level/newimg/Mask group@3x_1.png");
  background-size: 100% 100%; */
  /* overflow-y: scroll; */
  position: relative;
}

/* .bg {
  overflow: hidden;
  
} */

.box {
  position: relative;
  margin-top: 88px;
}

.head {
  display: flex;
  align-items: center;
  /* background: #1d1d1d; */
  justify-content: center;
  padding: 0 0.27rem;
  height: 44px;
  margin: 0 !important;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  padding-top: 44px;
}

.returns {
  width: 0.5rem;
  height: 0.5rem;
}

.pagetitle {
  font-size: 0.3rem;
  font-family: Regular;
  font-weight: bold;
  color: #E2AA78;
  line-height: 0.5rem;
  flex: 1;
  text-align: center;
  margin-right: 0.4rem;
}

.flexs {
  display: flex;
  align-items: center;
}

.dingwei {
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
}

.van-tab--active {
  color: #323233;
  font-weight: 600;
}

.urlRank-icon {
  width: 168px;
  height: 168px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.urlRank {
  width: 108px;
  height: 108px;
  background-size: 100% 100%;
}

.urlRank img {
  width: 0.3rem;
  height: 0.28rem;
  margin-left: 0.1rem;
}

.progress_barbox {
  width: 100%;
  height: 0.13rem;
  background: #3B3327;
  border-radius: 7px;
  font-size: 0.2rem;
  position: relative;
  overflow: hidden;
}

.currentExpbox {
  height: 1rem;
  font-size: 0.25rem;
  padding: 0.5rem 0.76rem 0;
  font-family: Regular;
}

.currentExp {
  height: 0.14rem;
  border-radius: 7px;
  background: linear-gradient(
    270deg,
    #f8e1b5 0%,
    rgba(248, 225, 181, 0.2) 100%
  );
}

.rank {
  padding: 0 0.1rem;
}

.beijin {
  font-size: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Semibold;
  padding-top: 116px;
  max-height: 22px;
}

.personLevel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  color: #deb888;
  text-align: center;
}

.wealthLevel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-left: 0.5rem; */
  color: rgb(216 186 150 / 30%);
  text-align: center;
}

.charmLevel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  color: rgb(216 186 150 / 30%);
  text-align: center;
}

html {
  background: #000;
  overflow-x: hidden;
  height: 11.3rem;
}

.reward {
  color: #deb888;
  font-size: 0.25rem;
  font-family: Regular;
}

.rankExh {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border: 1px solid #deb888;
  margin-top: 0.6rem;
}

.rankExhbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.52rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.rankExhbox img:nth-child(1) {
  margin-right: 0.4rem;
}

.rankExhbox img:nth-child(3) {
  margin-left: 0.4rem;
}

.van-grid-item__content {
  background: none;
}

.van-grid {
  justify-content: center;
}

.rankvalue {
  color: #deb888;
  font-size: 0.25rem;
  font-family: Regular;
  margin-top: 10px;
}

.avatarFrame {
  overflow-y: scroll;
  display: flex;
  white-space: break-all ;
  margin: 0 17px;
}

.ffectsimg {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}

.avatarFramebox {
  width: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 0.2rem;
}

.help {
  text-align: center;
  /* margin-bottom: 68px; */
}

.titleName {
  color: #deb888;
  font-size: 0.23rem;
  /* margin-bottom: 0.2rem; */
}

.van-grid-item__content {
  padding: 0 0 0.5rem;
}

.upgradebox {
  white-space: normal;
  justify-content: center;
  margin: 0 1.17rem;
  margin-bottom: 0.15rem;
}

.contentbox {
  max-width: 235px;
  word-wrap: normal;
  text-align: left;
  font-family: Regular;
  margin-left: 0.3rem;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.urlRank_box {
  width: 95px;
  overflow: hidden;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}
.level-box{
  width: 347px;
min-height: 190px;
padding-top: 40px;
background: #281F22;
opacity: 1;
border: 2px solid #4F3F44;
border-radius: 22px;
margin: 0 auto 40px auto;
position: relative;
}
.level-img{
  width: 182px;
  height: 38px;
  position: absolute;
  top:0%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.level-txt{
  /* height: 38px; */
  font-size: 0.3rem;
  /* line-height: 38px; */
  text-align: center;
  font-family: Montserrat-Bold, Montserrat;
font-weight: bold;
color: #000000;
  position: absolute;
  top:0%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin: 0;
}

.avatar-frame{
}

</style>